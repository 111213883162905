<template>
  <div>
    <h1>This is page Campaigns</h1>
  </div>
</template>
<script>
export default {
  name: 'Campaigns',
  data: () => ({}),
}
</script>
